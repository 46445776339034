import React from "react"

import SEO from "../components/seo"

import "../components/scss/app.scss"

class ThankYouPage extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            window.location.href= "/"
        }, 4000);
    }
    render() {
        return (
            <>
            <SEO title="Oakville Cosmetic Dentistry - Thank You" />
            <div class="thank-you-container">
          <a href="/"><img src="/mobile/inc/images/logo.png" class="img-responsive" /></a>
          <p>Thank you for your inquiry. A member from the Smile Stylist team will be in contact with you shortly. If you have any questions or would like to speak with us directly, please call us Monday to Friday, 9am-5pm at <a href="tel:9058490558">(905) 849-0558</a>. Thank you.</p>
            </div>
          </>
        )
    }
}

export default ThankYouPage